import {
    queryVevo,
    queryVevoSH,
    queryBoxList,
    queryBoxListSH,
} from "@/api/tracking";
import {
    queryQDBlnoByPage,
    queryQDVevo,
    queryQDBlno,
    updateQDVv,
    queryQDBlnoDetail,
    updateBlnoMain,
} from "@/api/qingdaoLogistics";
import {
    queryTJBlnoByPage,
    queryTJVevo,
    queryTJBlno,
    updateTJVv,
    queryTJBlnoDetail,
    updateTJBlnoMain,
} from "@/api/tianjingLogistics";
import {
    queryXMBlnoByPage,
    queryXMVevo,
    queryXMBlno,
    updateXMVv,
    queryXMBlnoDetail,
    updateXMBlnoMain,
} from "@/api/xiamenLogistics";
import {
    queryBlno,
    queryBlnoSH,
    updateVesselsNameAndVoyage,
    updateUserBlnoSH,
    queryDetailBoxInfo,
    queryBlnoDetailSH,
} from "@/api/boxQuery";

/**
 * @description 物流跟踪的接口请求，根据不同tab来区分
 * @param  tab NB SH QD TJ
 * @param type 1.分页查询箱单  2.查询船名航次  3.查询提单号 4.整柜订阅地址 5.拼箱订阅地址 6.修改船名航次 7.查详情 8.修改主单号
 */
export const logisticsRequest = ({ tab, type }) => {
    switch (tab) {
        case "NB":
            switch (type) {
                case 1:
                    return queryBoxList;
                case 2:
                    return queryVevo;
                case 3:
                    return queryBlno;
                case 4:
                    return "/blno/userBlno/subscribe";
                case 5:
                    return "/blno/userBlno/recallDeleted";
                case 6:
                    return updateVesselsNameAndVoyage;
                case 7:
                    return queryDetailBoxInfo;
            }
        case "SH":
            switch (type) {
                case 1:
                    return queryBoxListSH;
                case 2:
                    return queryVevoSH;
                case 3:
                    return queryBlnoSH;
                case 4:
                    return "/blno/userBlnoSh/subscribe";
                case 5:
                    return "/blno/userBlnoSh/recallDeleted";
                case 6:
                    return updateUserBlnoSH;
                case 7:
                    return queryBlnoDetailSH;
            }
        case "QD":
            switch (type) {
                case 1:
                    return queryQDBlnoByPage;
                case 2:
                    return queryQDVevo;
                case 3:
                    return queryQDBlno;
                case 4:
                    return "/blno/userBlnoQd/subscribe";
                case 5:
                    return "/blno/userBlnoQd/recallDeleted";
                case 6:
                    return updateQDVv;
                case 7:
                    return queryQDBlnoDetail;
                case 8:
                    return updateBlnoMain;
            }
        case "TJ":
            switch (type) {
                case 1:
                    return queryTJBlnoByPage;
                case 2:
                    return queryTJVevo;
                case 3:
                    return queryTJBlno;
                case 4:
                    return "/blno/userBlnoTj/subscribe";
                case 5:
                    return "/blno/userBlnoTj/recallDeleted";
                case 6:
                    return updateTJVv;
                case 7:
                    return queryTJBlnoDetail;
                case 8:
                    return updateTJBlnoMain;
            }
        case "XM":
            switch (type) {
                case 1:
                    return queryXMBlnoByPage;
                case 2:
                    return queryXMVevo;
                case 3:
                    return queryXMBlno;
                case 4:
                    return "/blno/userBlnoXm/subscribe";
                case 5:
                    return "/blno/userBlnoXm/recallDeleted";
                case 6:
                    return updateXMVv;
                case 7:
                    return queryXMBlnoDetail;
                case 8:
                    return updateXMBlnoMain;
            }
    }
};

/**
 * @description 过滤陆海通的数据
 * @param {String} value  需要过滤的数据
 * @returns {String}
 */
export const luhaitongFilter = (value) => {
    if (!value) {
        return "";
    }
    if (value.includes("陆海通_")) {
        value = value.replace("陆海通_", "");
    }
    return value;
};
